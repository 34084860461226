import { Ask } from "./Ask";

type Result = string;

export const AskPodcast = ({
  initialQuery = "",
  initialAnswer = "",
  persistUrlState = false,
}: {
  initialQuery?: string;
  initialAnswer?: string;
  persistUrlState?: boolean;
}) => {
  const onShare = async () => {
    const url = new URL(window.location.href);
    const question = url.pathname.split("/").pop() || "";
    const link = `${url.origin}/podcast/${question}#askPodcast-answer`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        const shareButton = document.querySelector("[data-share-button]");
        if (shareButton) {
          const originalText = shareButton.textContent;
          shareButton.textContent = "Link Copied";
          shareButton.classList.remove("text-white");
          shareButton.classList.add("text-green-500");
          shareButton.setAttribute("disabled", "true");
          setTimeout(() => {
            shareButton.textContent = originalText;
            shareButton.classList.remove("text-green-500");
            shareButton.classList.add("text-white");
            shareButton.removeAttribute("disabled");
          }, 2000);
        }
      })
      .catch((err) => {
        console.error("Error copying to clipboard:", err);
      });
  };

  return (
    <Ask<Result>
      initialQuery={initialQuery}
      persistUrlState={persistUrlState}
      initialAnswer={initialAnswer}
      pills={[
        "Hosting my own LLM",
        "Fine-grained Reactivity",
        "Work/Life Balance",
        "Diversity & Inclusion",
      ]}
      id="ask-podcast"
      placeholders={[
        "How do I be more productive?",
        "Should I choose Vercel or Netlify?",
        "What's the role of Sentry in applications?",
        "What makes the web epic?",
        "What's the best framework for web apps today?",
      ]}
      apiRoute="askPodcast"
      stream
    >
      <div class="flex items-center gap-2 bg-black text-xs justify-between py-1 px-4">
        <span>
          ✨ Click on the{" "}
          <span class="pointer-events-none" data-ai-podcast-link>
            timestamps
          </span>
          .
        </span>
        <button
          type="button"
          data-share-button
          onclick={onShare}
          class="bg-black rounded-full py-2 text-white font-bold"
        >
          Share
        </button>
      </div>
    </Ask>
  );
};
